@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/placeholders.scss';
@import '../../styles/abstract/mixins.scss';

.dynamic-button {
  @include button;
  @include text($color: $white);
  width: 100%;
  max-width: 16rem;
  min-height: 5rem;
  border-radius: 1rem;
  background-color: $button;
  outline: none;
  @extend %transition-all;

  @include media-hover {
    &:hover {
      background-color: $button-hover;
    }
  }

  &:active {
    background-color: $button-hover;
  }

  &_delete {
    @include text($color: $white, $font-size: 1.4rem);
    width: 2rem;
    min-height: 2rem;
    height: 2rem;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 0.6rem;
  }

  @include media-small {
    @include text($font-size: 1.4rem, $color: $white);
    min-height: 3rem;
    max-width: 12rem;
    border-radius: 0.6rem;

    &_delete {
      min-height: 2rem;
    }
  }
}
