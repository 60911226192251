@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/placeholders.scss';
@import '../../styles/abstract/mixins.scss';

.form {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: repeat(auto-fit, auto);
  align-items: flex-end;
}

.form__buttons {
  padding: 2rem 0 3rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  @include media-small {
    padding: 1rem 0 2rem;
  }
}

.form__button {
  @include button;
  @include text($color: $white);
  width: 100%;
  max-width: 16rem;
  min-height: 5rem;
  border-radius: 1rem;
  background-color: $button;
  outline: none;
  @extend %transition-all;

  @include media-hover {
    &:hover {
      background-color: $button-hover;
    }
  }

  &:active {
    background-color: $button-hover;
  }

  &_disabled {
    color: $border-active;
    @include disabled;
    background-color: $disable;
  }

  @include media-small {
    @include text($font-size: 1.4rem, $color: $white);
    min-height: 3rem;
    max-width: 12rem;
    border-radius: 0.6rem;
  }
}
