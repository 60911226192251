@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/placeholders.scss';

.sort {
  padding: 0;
  position: relative;
  width: fit-content;
  height: fit-content;

  &_active {
    border-radius: 0.8rem 0.8rem 0 0;
    box-shadow: 0 -3px 15px 4px rgba(122, 112, 112, 0.1);
  }
}

.sort__wrapper {
  width: 10rem;
  height: 5.4rem;
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
  border: 1px solid $border;
  background: $white;
  @extend %transition-all;

  &_active {
    border-radius: 0.8rem 0.8rem 0 0;
    border-bottom: none;

    & .sort__arrow {
      transform: rotate(180deg);
    }
  }

  @include media-small {
    width: 8rem;
    height: 3.8rem;
  }
}

.sort__arrow {
  width: 1.4rem;
  height: 0.9rem;
  background-image: url(../../images/icons/arrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @extend %transition-all;
}

.sort__button {
  @include button;
  @include text;
  padding: 1.2rem;
  width: 100%;
  justify-content: space-between;

  @include media-small {
    @include text($font-size: 1.4rem);
    padding: 1rem;
  }
}

.sort__list {
  @include list;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid $border;
  border-top: none;
  border-radius: 0 0 0.8rem 0.8rem;
  background: $white;
  box-shadow: 0 10px 10px 4px rgba(122, 112, 112, 0.1); /* получившаяся тень */
  visibility: hidden;
  opacity: 0;
  @extend %transition-all;

  &_active {
    top: 100%;
    z-index: 5;
    visibility: visible;
    opacity: 1;
  }
}

.sort__item {
  position: relative;
}

.sort__label {
  @include button;
  padding: 1.2rem;
  width: 100%;
  height: 5.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @extend %transition-all;

  span {
    @include text;
  }

  @include media-small {
    padding: 1rem;
    height: 3.8rem;

    span {
      @include text($font-size: 1.4rem);
    }
  }

  @include media-hover {
    &:hover {
      background-color: $bg-primary;
      border-radius: 0.8rem;

      span {
        @include text($font-weight: $fw-semibold);
      }
    }
  }
}

.sort__radio {
  width: 1px;
  height: 1px;
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
