%transition-opacity {
  transition: opacity 0.4s linear;
}

%transition-all {
  transition: all 0.3s ease-in;
}

%font-prim {
  font-family: 'Manrope', 'Arial', sans-serif;
}
