@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/placeholders.scss';

.header {
  padding: 4rem 0;
  background-color: $bg-primary;
  border-bottom: 1px solid $border;

  nav {
    width: 100%;
  }

  @include media-tablet {
    padding: 2rem 0;
  }
}

.navigation {
  @include list;
  margin: auto;
  max-width: $medium-width;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include media-tablet {
    max-width: $small-width;
  }

  @include media-small {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  @include media-mobile {
    flex-direction: column;
  }
}

.navigation__box {
  padding: 0.5rem;
  border-radius: 0.8rem;

  @include media-hover {
    &:hover {
      background-color: $white;
    }
  }

  @include media-tablet {
    background-color: $white;
  }
}

.navigation__item {
  @include link;
  @include text($font-size: 1.6rem);
  width: 14rem;
  display: flex;

  &_type_active {
    @include text($font-size: 1.6rem, $font-weight: $fw-semibold);
  }

  @include media-tablet {
    @include text($font-size: 1.2rem);
    width: 10rem;

    &_type_active {
      @include text($font-size: 1.2rem, $font-weight: $fw-semibold);
    }
  }

  @include media-mobile {
    width: 100%;
    justify-content: center;
  }
}
