$white: #fff;
$black: #000;
$button: #2e2ee5;
$button-hover: #0000b2;
$bg-primary: #f6f6f6;
$bg-active: #ededed;
$border: #e5e5e5;
$border-active: #d7d7d7;
$disable: #b0b0b0;
$error: #dd4c1e;
$bg-overlay: rgba(0, 0, 0, 0.5);

// для медиа-запросов
$content-width: 192rem;
$medium-width: 1280px;
$laptop-width: 1024px;
$tablet-width: 950px;
$small-width: 750px;
$mobile-width: 550px;

/* font */
$fw-extralight: 200;
$fw-regular: 400;
$fw-semibold: 600;
