@import '../../styles/abstract/placeholders.scss';
@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/mixins.scss';

.input {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border-active;

  @include media-mobile {
    flex-direction: column;
    gap: 1rem;
  }
}

.input__name {
  @include text;
  padding-top: 1.2rem;

  @include media-small {
    @include text($font-size: 1.4rem);
    padding-top: 1rem;
  }

  @include media-mobile {
    padding-top: 0;
  }
}

.input__wrap {
  width: 50%;
  position: relative;

  @include media-mobile {
    width: 100%;
  }
}

.input__field {
  @include text;
  padding: 1.6rem;
  width: 100%;
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid $border;
  border-radius: 8px;
  caret-color: $black;
  outline: none;

  &:focus {
    background-color: $white;
    border: 1px solid $border-active;
  }

  &_type_error {
    color: $error;
  }

  &::-webkit-input-placeholder {
    color: $black;
    @extend %font-prim;
    font-style: normal;
  }

  &::-moz-placeholder {
    color: $black;
    @extend %font-prim;
    font-style: normal;
  }

  &::-ms-input-placeholder {
    color: $black;
    @extend %font-prim;
    font-style: normal;
  }

  &::placeholder {
    color: $black;
    @extend %font-prim;
    font-style: normal;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $white inset;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $black;
  }

  @include media-small {
    @include text($font-size: 1.4rem);
    padding: 1rem;
  }
}

.input__error {
  min-height: 2.4rem;
  display: flex;
  align-items: center;
  color: $error;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}

.input__arrow {
  @include button;
  position: absolute;
  top: 2.3rem;
  right: 2.3rem;
  width: 1.4rem;
  height: 0.9rem;
  background-image: url(../../images/icons/arrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @extend %transition-all;

  &_active {
    transform: rotate(180deg);
  }

  @include media-small {
    top: 1.5rem;
    right: 1.3rem;
  }
}

.material {
  @include list;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid $border;
  border-top: none;
  border-radius: 0 0 0.8rem 0.8rem;
  background: $white;
  box-shadow: 0 10px 10px 4px rgba(122, 112, 112, 0.1);
  visibility: hidden;
  opacity: 0;
  @extend %transition-all;

  &_active {
    top: 5.5rem;
    z-index: 5;
    visibility: visible;
    opacity: 1;
    max-height: 16.2rem;
    overflow-y: auto;
    @include scrollbars;
  }

  @include media-small {
    &_active {
      top: 3.8rem;
      max-height: 12.2rem;
    }
  }
}

.material__button {
  @include button;
  padding: 1.2rem;
  width: 100%;
  height: 5.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @extend %transition-all;
  @include text;

  @include media-hover {
    &:hover {
      background-color: $bg-primary;
      border-radius: 0.8rem;

      @include text($font-weight: $fw-semibold);
    }
  }

  @include media-small {
    @include text($font-size: 1.4rem);
    height: 3.8rem;
  }
}
