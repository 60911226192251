* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

#root {
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100vh;
  @extend %font-prim;
  font-style: normal;
  color: $black;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  background-color: $white;
}

.page {
  margin: 0;

  &_lock {
    overflow: hidden;

    @include media-hover {
      padding-right: 17px;
    }
  }
}

.wrapper {
  margin: 0 auto;
  padding: 0 18rem;
  max-width: $content-width;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  @include media-medium {
    padding: 0 12rem;
  }

  @include media-laptop {
    padding: 0 8rem;
  }

  @include media-tablet {
    padding: 0 4rem;
  }

  @include media-mobile {
    padding: 0 1rem;
  }
}

.background {
  margin: 0;
  padding: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  @extend %transition-all;
  z-index: 9;

  &_active {
    visibility: visible;
  }
}

.title {
  @include section-title;
}

.fieldset {
  margin: 0;
  margin-bottom: 2rem;
  padding: 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border: 1px solid $border-active;
  border-radius: 1rem;

  &_thin {
    padding-right: 4.3rem;
  }

  @include media-small {
    margin-bottom: 1rem;
    justify-content: flex-end;

    &_dynamic {
      flex-wrap: wrap;
    }

    &_thin {
      padding-right: 3.8rem;
    }
  }

  @include media-mobile {
    flex-direction: column;
  }
}

.fieldset__wrap {
  display: flex;
}

.fieldset__title {
  @include text;
  margin-right: auto;
  padding-top: 1.2rem;

  @include media-small {
    @include text($font-size: 1.4rem);
    padding-top: 0.6rem;
    margin-right: auto;
  }

  @include media-mobile {
    padding-top: 0;
  }
}

.fieldset__text {
  @include text;
  margin-right: 8rem;
  padding-top: 1.2rem;

  &_count {
    margin-right: 0;
  }

  @include media-small {
    @include text($font-size: 1.4rem);
    padding-top: 0.6rem;
  }
}
