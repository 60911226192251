@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/placeholders.scss';

.thickness {
  @include page-padding;
}

.thickness__container {
  padding: 1rem;
  max-width: 85rem;
  width: 100%;
  background-color: $bg-primary;
  border: 1px solid $border;
  border-radius: 1rem;
}

.thickness__wrap {
  padding: 0 0 3rem;
}

.thickness__result {
  width: 100%;
  margin: 2rem auto 1rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border-bottom: 1px solid $border-active;

  p {
    @include text;
  }

  @include media-small {
    padding-bottom: 2rem;

    p {
      @include text($font-size: 1.4rem);
    }

    span {
      @include text($font-size: 1.4rem);
    }
  }

  @include media-mobile {
    flex-direction: column;
  }
}

.thickness__result-wrap {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.thickness__result-value {
  @include text($font-weight: $fw-semibold);
  min-width: 10rem;
  text-align: center;
}

.thickness__button {
  @include button;
  @include text($color: $white);
  margin: 2rem auto;
  width: 100%;
  max-width: 16rem;
  min-height: 5rem;
  border-radius: 1rem;
  background-color: $button;
  outline: none;
  @extend %transition-all;

  @include media-hover {
    &:hover {
      background-color: $button-hover;
    }
  }

  &:active {
    background-color: $button-hover;
  }
}
