// для media-запросов
@mixin media-small {
  @media (max-width: $small-width) {
    @content;
  }
}

@mixin media-medium {
  @media (max-width: $medium-width) {
    @content;
  }
}

@mixin media-laptop {
  @media (max-width: $laptop-width) {
    @content;
  }
}

@mixin media-tablet {
  @media (max-width: $tablet-width) {
    @content;
  }
}

@mixin media-small {
  @media (max-width: $small-width) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin media-hover {
  @media (hover: hover) {
    @content;
  }
}

// списки, ссылки, кнопки, иконки
@mixin list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin link($color: $black) {
  text-decoration: none;
  color: $color;
}

@mixin button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@mixin disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin icon($url) {
  min-height: 2.4rem;
  padding-left: 3.1rem;
  position: relative;

  &:before {
    content: '';
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background-image: url($url);
    background-size: cover;
  }
}

// для заголовков секций
@mixin section-title($color: $black) {
  margin: 0;
  color: $color;
  font-size: 2.1rem;
  font-weight: $fw-semibold;
  line-height: normal;

  @include media-tablet {
    font-size: 1.6rem;
  }
}

// для текста
@mixin text(
  $color: $black,
  $font-size: 1.8rem,
  $font-weight: $fw-regular,
  $line-height: normal
) {
  margin: 0;
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin scrollbars {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: $border;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $disable;
    border-radius: 3px;
    height: 40px;
  }
}

@mixin page-padding {
  padding: 4rem 0;

  @include media-small {
    padding: 2rem 0;
  }

  @include media-mobile {
    padding: 1rem 0;
  }
}
